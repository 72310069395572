import { NavLink } from "react-router-dom";
import { dateHelpers } from "@jauntin/utilities";
import MemberBreadcrumb from "./MemberBreadcrumb";
import {
  getUrl,
  MEMBER_PAGE,
  VIEW_CONTACTS_PAGE,
  MEMBERS_NOTES_PAGE,
} from "../../../Helpers/URLParser";
import { RxMemberProp } from "Features/Members/Rx/RxMember";

const MembersMenu = ({ rxMember: [state] }: { rxMember: RxMemberProp }) => {
  const member = state.entity.data;
  const { id, organization } = member;
  return (
    <div className="subsection-menu">
      <div className="subsection-menu__header">
        <MemberBreadcrumb member={member} />
        <h3 className="overflow-wrap-break-word">
          {member.firstName} {member.lastName}{" "}
        </h3>
        <div>
          <span className="subsection-menu__date">
            {`Created on ${dateHelpers.lettersAndNumbers(member.createdAt)}`}
          </span>
          {state.entity.loading && (
            <span className="subsection-menu__date ml-2">(Updating...)</span>
          )}
        </div>
      </div>
      <div className="subsection-menu__items">
        <NavLink
          to={getUrl(MEMBER_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Member
        </NavLink>
        {!Boolean(organization.doNotSendEmailsToMember) && (
          <NavLink
            to={getUrl(VIEW_CONTACTS_PAGE, id)}
            className="subsection-menu__item d-flex justify-content-between"
            activeClassName="active"
          >
            Contacts &amp; E-mailing
          </NavLink>
        )}
        <NavLink
          to={getUrl(MEMBERS_NOTES_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Notes
        </NavLink>
      </div>
    </div>
  );
};

export default MembersMenu;
