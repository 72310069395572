import { useReactable } from "@reactables/react";
import { Form, Field, FormArray, ControlModels } from "@reactables/react-forms";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { RxMemberContactsForm } from "Features/Members/Rx/RxMemberContactsForm";
import { RxMemberProp } from "Features/Members/Rx/RxMember";
import { AsyncButton, Button, TextInput } from "@jauntin/react-ui";
import {
  CustomContact,
  emptyCustomContact,
} from "Features/Members/Rx/Models/memberDetails.model";
import { customContact } from "Features/Members/Rx/Configs/memberContacts.config";
import CustomContactEmailField from "./CustomContactEmailField";
import LoadingSpinner from "Features/Shared/Components/LoadingSpinner";
import { RxToggle } from "@jauntin/reactables";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import { useHistory } from "react-router-dom";
import { getUrl, VIEW_CONTACTS_PAGE } from "Helpers/URLParser";

const EditMemberContacts = ({ rxMember }: { rxMember: RxMemberProp }) => {
  const [
    {
      entity: { data: memberDetails },
      customContacts,
    },
    rxMemberActions,
  ] = rxMember;

  const [state, actions] = useReactable(RxMemberContactsForm, {
    memberDetails,
    sources: [],
    edit: true,
  });

  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  const history = useHistory();

  if (!state) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Edit Contacts</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={discardModalToggle.toggleOn}
            />
            <AsyncButton
              spinning={customContacts.loading}
              disabled={!state.root.valid || !state.root.dirty}
              className="btn btn-primary ml-auto border-radius-0"
              onClick={() =>
                rxMemberActions.customContacts.send({
                  form: state,
                  memberDetails,
                })
              }
            >
              Save Changes
            </AsyncButton>
          </div>
        </div>
      </div>
      <div className="content__body">
        <Form rxForm={[state, actions]}>
          <Row>
            <Col>
              <Card className="mb-4">
                <Card.Header className="bg-white font-weight-bold">
                  Primary Member
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      {memberDetails.firstName} {memberDetails.lastName}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>{memberDetails.email}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <FormArray name="emailOrganizationProducerContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email } =
                    memberDetails.organization.facilityProducerContacts[index];
                  return (
                    <Row key={key}>
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Broker Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={6}>{fullName}</Col>
                            </Row>
                            <Row>
                              <Col md={6}>{email}</Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="emailOrganizationContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, role } =
                    memberDetails.organization.contacts.filter(
                      ({ copyOnEmails }) => copyOnEmails
                    )[index];

                  return (
                    <Row key={key}>
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Organization Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && (
                              <Row>
                                <Col md={6}>{fullName}</Col>
                              </Row>
                            )}
                            {role && (
                              <Row>
                                <Col md={6}>{role}</Col>
                              </Row>
                            )}
                            <Row>
                              <Col md={6}>{email}</Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="customContacts">
            {({ items, pushControl, removeControl }) => (
              <>
                {items.map(
                  (
                    {
                      key,
                      value: { contactInfo },
                    }: ControlModels.FormControl<{
                      sendEmail: boolean;
                      contactInfo: CustomContact;
                    }>,
                    index
                  ) => {
                    return (
                      <Row key={key}>
                        <Col>
                          <Card className="mb-4">
                            <Card.Header className="bg-white font-weight-bold d-flex justify-content-between">
                              Custom Contact #{index + 1}
                              <button
                                className="btn-link--black btn-link--delete btn btn-link"
                                onClick={() => removeControl(index)}
                              >
                                Delete
                              </button>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col md={6}>
                                  <Field
                                    name={`customContacts.${index}.contactInfo.fullName`}
                                    label="Full name"
                                    inputClassName="form-control-lg"
                                    component={TextInput}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Field
                                    name={`customContacts.${index}.contactInfo.email`}
                                    label="Email"
                                    inputClassName="form-control-lg"
                                    component={CustomContactEmailField}
                                    memberDetails={memberDetails}
                                    index={index}
                                    emailControl={
                                      state[
                                        `customContacts.${index}.contactInfo.email`
                                      ]
                                    }
                                    contactsFormGroup={state.root}
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    );
                  }
                )}
                {items.length < 2 && (
                  <Row>
                    <Col>
                      <Button
                        text="Add Custom Contact"
                        className="btn btn-outline-secondary w-100 mt-4"
                        onClick={() =>
                          pushControl(customContact(emptyCustomContact))
                        }
                      ></Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </FormArray>
        </Form>
      </div>

      {customContacts.loading && (
        <div className="disable-overlay">
          <div className="disable-overlay__inner">
            <LoadingSpinner />
            <div className="h6 mt-2">Saving...</div>
          </div>
        </div>
      )}

      <Modal
        show={customContacts.success || Boolean(customContacts.error)}
        size="lg"
      >
        <Modal.Header
          className="w-100 border-0 pb-0"
          closeButton
          onHide={() => {
            rxMemberActions.customContacts.resetState();
            history.push(getUrl(VIEW_CONTACTS_PAGE, memberDetails.id));
          }}
        />
        <Modal.Body className="text-center px-5">
          <p>
            {customContacts.success
              ? "Membership contacts have been successfully saved."
              : "There was an error saving membership contacts. Please try again."}
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 mb-3">
          <Button
            className="btn btn-primary px-5"
            onClick={() => {
              rxMemberActions.customContacts.resetState();
              history.push(getUrl(VIEW_CONTACTS_PAGE, memberDetails.id));
            }}
            text="OK"
          />
        </Modal.Footer>
      </Modal>

      {showDiscardModal && (
        <ModalDiscardAddNew
          show={showDiscardModal}
          handleClose={discardModalToggle.toggleOff}
          confirmAction={() => {
            history.push(getUrl(VIEW_CONTACTS_PAGE, memberDetails.id));
          }}
        />
      )}
    </>
  );
};

export default EditMemberContacts;
