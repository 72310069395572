import React from "react";
import { useReactable } from "@reactables/react";
import { Spinner } from "react-bootstrap";
import MembersMenu from "./MembersMenu";
import CustomSwitch from "@basicare/common/src/Components/CustomSwitch";
import { Route, useParams, useHistory } from "react-router-dom";
import {
  EDIT_MEMBER_PAGE,
  getUrl,
  MEMBER_PAGE,
  VIEW_CONTACTS_PAGE,
  EDIT_CONTACTS_PAGE,
  MEMBERS_NOTES_PAGE,
} from "Helpers/URLParser";
import { RxMember } from "../Rx/RxMember";
import MembershipService from "Services/MembershipService";
import API from "Services/API";
import MemberView from "./MemberView";
import EditMember from "./EditMember";
import ViewMemberContacts from "./ViewMemberContacts";
import EditMemberContacts from "./EditMemberContacts";
import MemberNotes from "./MemberNotes";
import FacilityService from "Services/FacilityService";

const Member = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const rxMember = useReactable(RxMember, {
    membershipService: new MembershipService(new API()),
    facilityService: new FacilityService(new API()),
    id: parseInt(id),
    onSaveMemberSuccess: () => {
      history.push(getUrl(MEMBER_PAGE, id));
    },
  });

  const [state] = rxMember;

  if (!state) return;

  const { entity: member } = state;

  if (member.loading && !member.data)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <Spinner animation="border" role="status" />
        <span className="ml-3">Loading Member...</span>
      </div>
    );

  return (
    <>
      <div className="subsection__wrapper">
        <MembersMenu rxMember={rxMember} />
        <div className="subsection__container">
          <div>
            <CustomSwitch notFoundRedirectUrl={getUrl(MEMBER_PAGE, id)}>
              <Route path={getUrl(MEMBER_PAGE)}>
                <MemberView rxMember={rxMember} />
              </Route>
              {!member.data.organization.doNotSendEmailsToMember && (
                <Route path={getUrl(VIEW_CONTACTS_PAGE)}>
                  <ViewMemberContacts rxMember={rxMember} />
                </Route>
              )}
              {!member.data.organization.doNotSendEmailsToMember && (
                <Route path={getUrl(EDIT_CONTACTS_PAGE)}>
                  <EditMemberContacts rxMember={rxMember} />
                </Route>
              )}
              <Route path={getUrl(EDIT_MEMBER_PAGE)}>
                <EditMember rxMember={rxMember} />
              </Route>
              <Route path={getUrl(MEMBERS_NOTES_PAGE)}>
                <MemberNotes rxMember={rxMember} />
              </Route>
            </CustomSwitch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Member;
